import React, { useState, useEffect } from "react";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import { nanoid } from "@reduxjs/toolkit";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { AuctionItemImageFile } from "../../auction/auctionsSlice";
import { UploadProps } from "antd/lib/upload";

interface Props {
  eventId: string;
  auctionItemId: string;
  images?: AuctionItemImageFile[];
}
const ImageUploader: React.FC<Props> = ({
  eventId,
  auctionItemId,
  images = [],
}) => {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [fileList, setFileList] = useState<any[]>(() => images);
  const [isUpdated, setIsUpdated] = useState(false);

  //   const handleChange = (info: any) => {
  //     if (info.file.status === "uploading") {
  //       setState({ loading: true });
  //       return;
  //     }
  //     if (info.file.status === "done") {
  //       getBase64(info.file.originFileObj, (imageUrl) =>
  //         setState({
  //           imageUrl,
  //           loading: false,
  //         })
  //       );
  //     }
  //   };

  useEffect(() => {
    if (fileList.length > 0 && isUpdated) {
      firestore
        .update(
          {
            collection: "events",
            doc: eventId,
            subcollections: [{ collection: "items", doc: auctionItemId }],
          },
          { images: fileList }
        )
        .then(() => {
          console.log("Item images updated!");
        })
        .catch((err) => {
          message.error("There was an error attaching this image to the item");
        });
    }
  }, [fileList, isUpdated, auctionItemId, eventId, firestore]);

  const handleChange = (info: any) => {
    console.log("handleChange", info);
  };

  const handleRemove: UploadProps["onRemove"] = (file) => {
    const baseImagePath = `images/${eventId}/${auctionItemId}`;
    const storage = firebase.storage();

    storage
      .ref(`${baseImagePath}/${file.name}`)
      .delete()
      .finally(() => {
        setFileList((prev) => prev.filter((image) => image.uid !== file.uid));

        if (!isUpdated) {
          setIsUpdated(true);
        }

        message.success("Image removed!");
      });
    // .catch((err) => message.error("There was an error deleting this image"));
  };

  const beforeUpload: UploadProps["beforeUpload"] = (file) => {
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("You can only upload image files");
    }

    // You can remove this validation if you want
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error(
        "Image must smaller than 1MB. You should optimize this before uploading it."
      );
    }
    return isImage && isLt5M;
  };

  const customUpload = async ({ onError, onSuccess, file }: any) => {
    const baseImagePath = `images/${eventId}/${auctionItemId}`;
    const storage = firebase.storage();
    const uploadTask = storage.ref(`${baseImagePath}/${file.name}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const progress = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // );
        // setProgress(progress);
      },
      (error) => {
        console.log(error);
        onError(error);
      },
      () => {
        storage
          .ref(baseImagePath)
          .child(file.name)
          .getDownloadURL()
          .then(async (url) => {
            // setUrl(url);
            const newEntry = {
              uid: nanoid(),
              url,
              name: file.name,
              status: "done",
            };

            setFileList((prev) => {
              return prev.concat(newEntry);
            });
            setIsUpdated(true);
          });

        onSuccess(null, uploadTask);
      }
    );
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <ImgCrop rotate aspect={1.7}>
      <Upload
        listType="picture-card"
        fileList={fileList as any}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={customUpload}
        onRemove={handleRemove}
      >
        {fileList.length < 10 && "+ Upload"}
      </Upload>
    </ImgCrop>
  );
};

export default ImageUploader;
