import * as React from "react";
import { Col, Carousel, Row, Typography, Empty, Card, Statistic } from "antd";
import { FileImageTwoTone } from "@ant-design/icons";
import { useTypedSelector } from "../../store";
import { selectAuctionItemById } from "./auctionsSlice";

const { Title } = Typography;

interface Props {
  id: string;
}
const AuctionItem: React.FC<Props> = ({ id }) => {
  const auctionItem = useTypedSelector((state) =>
    selectAuctionItemById(state, id)
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        {auctionItem.images?.length >= 1 ? (
          <Carousel autoplay style={{ maxHeight: 300, overflow: "hidden" }}>
            {auctionItem.images.map((image, index) => (
              <div key={`${image}-index`}>
                <img
                  alt={`${image.name}`}
                  src={image.url}
                  style={{ maxWidth: "100%" }}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <Empty
            description=""
            image={
              <FileImageTwoTone style={{ fontSize: 90 }} twoToneColor="#eee" />
            }
          />
        )}
      </Col>
      <Col span={24}>
        <Card size="small" title="Bidding Information" bordered={false}>
          <Row gutter={[16, 16]}>
            <Col xs={8}>
              <Card>
                <Statistic
                  title="Current Bid"
                  value={
                    auctionItem.starting_bid >
                    Number(auctionItem.currentAmount || 0)
                      ? auctionItem.starting_bid
                      : auctionItem.currentAmount
                  }
                  precision={0}
                  prefix="$"
                />
              </Card>
            </Col>
            <Col xs={8}>
              <Card>
                <Statistic
                  title="Next Bid"
                  value={
                    (auctionItem.starting_bid >
                    Number(auctionItem.currentAmount || 0)
                      ? auctionItem.starting_bid
                      : auctionItem.currentAmount) +
                    (auctionItem.minimum_bid_increment || 5)
                  }
                  precision={0}
                  prefix="$"
                />
              </Card>
            </Col>
            <Col xs={8}>
              <Card>
                <Statistic
                  title="Min. Bid"
                  value={auctionItem.minimum_bid_increment}
                  precision={0}
                  prefix="$"
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Row>
          <Title level={4}>Description</Title>
        </Row>
        <div dangerouslySetInnerHTML={{ __html: auctionItem.description }} />
      </Col>
      {/* <Col>{JSON.stringify(auctionItem, null, 2)}</Col> */}
    </Row>
  );
};

export default AuctionItem;
