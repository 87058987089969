import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useTypedSelector } from "../store";
import { Spin } from "antd";

const PrivateRoute: React.FC<RouteProps & { roles?: string[] }> = ({
  children,
  roles,
  ...rest
}) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);

  if (!isLoaded(auth) || !isLoaded(profile)) {
    return <Spin />;
  }
  let passGo = true;
  if (roles && roles.length >= 1) {
    passGo = roles.some((role) =>
      profile.roles ? profile.roles[role] : false
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isEmpty(auth) ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        ) : passGo ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
