import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Input, Row, Col, Card, message, Form } from "antd";
import { useFirebase } from "react-redux-firebase";
import GoogleButton from "react-google-button";
import { useForm, Controller } from "react-hook-form";

type RegisterValues = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};
const RegisterPage = () => {
  const history = useHistory();

  const firebase = useFirebase();

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<RegisterValues>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
  });

  const googleLogin = () =>
    firebase
      .login({ provider: "google", type: "popup" })
      .then((result) => history.push("/"))
      .catch((err) => message.error(err.message));

  const handleEmailSignup = (user: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
  }) =>
    firebase
      .createUser(user, {
        email: user.email,
        username: user.email,
        displayName: `${user.first_name} ${user.last_name}`,
      })
      .then((result) => history.push("/settings/billing"))
      .catch((err) => message.error(err.message));

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={{ span: 8, offset: 8 }}>
        <h1 style={{ textAlign: "center" }}>Create your account</h1>
        <Card>
          <form onSubmit={handleSubmit(handleEmailSignup)}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  labelCol={formItemLayout.labelCol}
                  labelAlign="left"
                  hasFeedback
                  wrapperCol={formItemLayout.wrapperCol}
                  validateStatus={errors.first_name ? "error" : "success"}
                  style={{ marginBottom: 0 }}
                >
                  <Controller
                    as={<Input />}
                    control={control}
                    name="first_name"
                    rules={{ required: "Please enter your last name" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  labelCol={formItemLayout.labelCol}
                  labelAlign="left"
                  hasFeedback
                  wrapperCol={formItemLayout.wrapperCol}
                  validateStatus={errors.last_name ? "error" : "success"}
                  style={{ marginBottom: 0 }}
                >
                  <Controller
                    as={<Input />}
                    control={control}
                    name="last_name"
                    rules={{ required: "Please enter your last name" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ textAlign: "left", marginBottom: 0 }}>
                <Form.Item
                  label="Email"
                  labelCol={formItemLayout.labelCol}
                  labelAlign="left"
                  hasFeedback
                  wrapperCol={formItemLayout.wrapperCol}
                  validateStatus={errors.email ? "error" : "success"}
                >
                  <Controller
                    as={
                      <Input
                        placeholder="your@email.com"
                        autoComplete="email"
                      />
                    }
                    control={control}
                    name="email"
                    rules={{ required: "You must enter a valid email" }}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  labelCol={formItemLayout.labelCol}
                  labelAlign="left"
                  hasFeedback
                  validateStatus={errors.password ? "error" : "success"}
                  wrapperCol={formItemLayout.wrapperCol}
                >
                  <Controller
                    as={
                      <Input.Password
                        placeholder="Your Password"
                        autoComplete="new-password"
                      />
                    }
                    control={control}
                    name="password"
                    rules={{ required: "You must enter a password" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ textAlign: "center" }}>
                <Button
                  loading={isSubmitting}
                  htmlType="submit"
                  type="primary"
                  size="large"
                  block
                >
                  Sign up
                </Button>
              </Col>
            </Row>
          </form>
          <div style={{ textAlign: "center", padding: "15px 0" }}>or</div>
          <div style={{ textAlign: "center" }}>
            <GoogleButton
              onClick={googleLogin}
              data-test-id="google-auth-button"
              style={{ margin: "0 auto" }}
              label="Sign up with Google"
              disabled={isSubmitting}
            />
          </div>

          <div style={{ textAlign: "center", marginTop: 30 }}>
            Already have an account? <Link to="/login">Sign in here</Link>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
export default RegisterPage;
