import * as React from "react";
import { Button, Input, Form as AntForm, Col, Card, message } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useFirebase } from "react-redux-firebase";

type SecurityFormData = {
  password: string;
  password_confirmation: string;
};

const SecurityPage: React.FC = () => {
  const firebase = useFirebase();
  const { handleSubmit, errors, control } = useForm<SecurityFormData>({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });
  const handleUpdatePassword = handleSubmit(
    ({ password, password_confirmation }) => {
      console.log(password, password_confirmation);
      firebase
        .auth()
        .currentUser?.updatePassword(password)
        .then(function () {
          // Update successful.
          message.success("Your password was updated.");
        })
        .catch(function (error) {
          // An error happened.
          console.log("Update Password error:", error);
          message.error("There was an error saving your password, try again");
        });
    }
  );

  return (
    <Col xs={24}>
      <Card title="Update your password">
        <form onSubmit={handleUpdatePassword}>
          <AntForm.Item
            validateStatus={errors.password ? "error" : "success"}
            label="Password"
          >
            <Controller
              as={<Input.Password autoComplete="new-password" />}
              control={control}
              name="password"
              rules={{ required: true }}
            />
          </AntForm.Item>

          <AntForm.Item
            validateStatus={errors.password_confirmation ? "error" : "success"}
            label="Confirm password"
          >
            <Controller
              as={<Input.Password autoComplete="new-password" />}
              control={control}
              name="password_confirmation"
              rules={{ required: true }}
            />
          </AntForm.Item>

          <Button htmlType="submit" type="primary" size="large">
            Update Password
          </Button>
        </form>
      </Card>
    </Col>
  );
};

export default SecurityPage;
