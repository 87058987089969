import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, DefaultThunkExtra } from "../../store";

export const makeBid = createAsyncThunk<
  {
    event_id: string;
    item_id: string;
    amount: number;
  },
  any,
  {
    extra: DefaultThunkExtra;
    rejectValue: {
      message: string;
    };
  }
>(
  "auctions/bid",
  async (
    { event_id, item_id, amount },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    try {
      const result = await axios.post(
        "https://us-central1-fallbrook-chamber-auction.cloudfunctions.net/bids",
        {
          event_id,
          item_id,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);

const slice = createSlice({
  name: "auctions",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(makeBid.fulfilled, (state, action) => {
      console.log("action", action);
    });
  },
});

export default slice.reducer;

export const selectAuctionItemById = (state: RootState, id: string) =>
  (state.firestore.data.items[id] || {}) as AuctionItem;
export const selectAllAuctionItems = (state: RootState) =>
  ((state.firestore.ordered.items || []) as any) as AuctionItem[];
export const selectTotalDonations = createSelector(
  [selectAllAuctionItems],
  (auctionItems) => {
    const sum = auctionItems.reduce((acc, curr) => {
      acc += curr.currentAmount;
      return acc;
    }, 0);
    return sum;
  }
);
export interface AuctionItem {
  id: string;
  category: string[];
  currentAmount: number;
  currentWinner: string;
  endAt: Date;
  image: string;
  images: AuctionItemImageFile[];
  stars: string[];
  startAt: Date;
  tags: string[];
  title: string;
  description: string;
  value: number;
  starting_bid: number;
  minimum_bid_increment: number;
  buy_now_price: number;
  active: boolean;
  mark_as_priceless: boolean;
}

export type AuctionItemImageFile = {
  url: string;
  uid: string;
  name: string;
  status: string;
};
