import React, { useEffect } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { UnpackNestedValue } from "react-hook-form/dist/types/form";
import { DeepPartial } from "react-hook-form/dist/types/utils";
import { Form as AntForm, Button, Input, Select, InputNumber } from "antd";
import { PRESET_DATA_TYPES } from "../../../store";
import { AuctionItem } from "../../auction/auctionsSlice";
import HtmlEditor from "./html-editor";

const Option = Select.Option;

type FormValues = Partial<AuctionItem>;
const ItemForm: React.FC<{
  onSubmit: SubmitHandler<FormValues>;
  defaultValues?: UnpackNestedValue<DeepPartial<FormValues>>;
  buttonText?: string;
}> = ({ onSubmit, buttonText, defaultValues }) => {
  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AntForm.Item
        hasFeedback
        validateStatus={errors.title ? "error" : "success"}
        label="Title"
      >
        <Controller
          as={<Input />}
          control={control}
          name="title"
          rules={{ required: "You must enter a name for this item" }}
        />
      </AntForm.Item>

      <AntForm.Item
        hasFeedback
        validateStatus={errors.category ? "error" : "success"}
        label="Categories"
      >
        <Controller
          as={
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select a category"
            >
              {PRESET_DATA_TYPES.categories.map((cat) => (
                <Option key={cat.value} value={cat.value}>
                  {cat.label}
                </Option>
              ))}
            </Select>
          }
          control={control}
          name="category"
          rules={{ required: true }}
        />
      </AntForm.Item>
      <AntForm.Item
        hasFeedback
        validateStatus={errors.starting_bid ? "error" : "success"}
        label="Starting bid"
      >
        <Controller
          as={
            <InputNumber
              defaultValue={5}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
            />
          }
          control={control}
          name="starting_bid"
          rules={{ required: "You must enter a starting bid for this item" }}
        />
      </AntForm.Item>

      <AntForm.Item
        hasFeedback
        validateStatus={errors.minimum_bid_increment ? "error" : "success"}
        label="Minimum bid increment"
      >
        <Controller
          as={
            <InputNumber
              defaultValue={5}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
            />
          }
          control={control}
          name="minimum_bid_increment"
          rules={{
            required: "You must enter a minimum bid increment for this item",
          }}
        />
      </AntForm.Item>

      <AntForm.Item
        hasFeedback
        validateStatus={errors.value ? "error" : "success"}
        label="Value"
      >
        <Controller
          as={
            <InputNumber
              defaultValue={100}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
            />
          }
          control={control}
          name="value"
          rules={{
            required: "You must enter a value for this item",
          }}
        />
      </AntForm.Item>

      <AntForm.Item label="Description">
        <Controller
          name="description"
          hasFeedback
          validateStatus={errors.description ? "error" : "success"}
          control={control}
          rules={{ required: true }}
          render={({ value, onChange }) => (
            <HtmlEditor content={value} onChange={onChange} />
          )}
        />
      </AntForm.Item>

      <Button
        htmlType="submit"
        type="primary"
        size="large"
        loading={isSubmitting}
        disabled={!isDirty}
      >
        {buttonText}
      </Button>
    </form>
  );
};

export default ItemForm;
