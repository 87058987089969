import React, { useState, ComponentProps } from "react";
import { useTypedSelector, EventSchema, Bid } from "../../store";
import {
  Button,
  Col,
  Tabs,
  message,
  Divider,
  List,
  Typography,
  Row,
  Statistic,
  Card,
  Avatar,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import EventForm, { EventFormData } from "./components/event-form";
import ItemForm from "./components/item-form";
import moment, { isMoment } from "moment";
import {
  DeleteOutlined,
  CopyOutlined,
  EditOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";
import ImageUploader from "./components/image-uploader";
import { AuctionItem } from "../auction/auctionsSlice";

const { TabPane } = Tabs;

const parseAndPrepareTimestamps = (event: EventSchema) => {
  return Object.entries(event).reduce((acc, [key, val]) => {
    if (["start_date", "end_date"].includes(key)) {
      acc[key] = moment((val as any) || undefined);
    } else {
      acc[key] = val;
    }
    return acc;
  }, {} as EventFormData & { [key: string]: any });
};
const EventDetails: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const location = useLocation();
  const history = useHistory();

  const [selectedItem, setSelectedItem] = useState<null | AuctionItem>(null);

  const firestore = useFirestore();

  const eventDetails =
    useTypedSelector((state) => state.firestore.data.events?.[eventId]) || {};
  const eventItems: AuctionItem[] =
    (useTypedSelector(
      (state) => state.firestore.ordered[`items.${eventId}`]
    ) as any) || [];
  const eventBids: Bid[] =
    useTypedSelector(
      (state) => state.firestore.ordered[`bids.${eventId}`] as any
    ) || [];

  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [itemMode, setItemMode] = useState<"create" | "update" | null>(null);

  useFirestoreConnect([
    {
      collection: `events`,
      doc: eventId,
      subcollections: [{ collection: "items" }],
      storeAs: `items.${eventId}`,
    },
    {
      collectionGroup: "bids",
      where: ["eventId", "==", eventId],
      storeAs: `bids.${eventId}`,
    },
  ]);

  //   useEffect(() => {
  //     firestore.get({
  //       collection: "events",
  //       doc: eventId,
  //     });
  //     firestore.get({
  //       collection: "events",
  //       doc: eventId,
  //       subcollections: [{ collection: "items" }],
  //       storeAs: `${eventId}.items`,
  //     });
  //   }, [eventId, firestore]);

  const handleUpdateEvent: ComponentProps<
    typeof EventForm
  >["onSubmit"] = async (event) => {
    const objectWithStringDates = Object.entries(event).reduce(
      (acc, [key, val]) => {
        if (isMoment(val)) {
          acc[key] = val.toISOString();
        } else {
          acc[key] = val;
        }
        return acc;
      },
      {} as typeof event & { [key: string]: any }
    );
    await firestore
      .update({ collection: "events", doc: eventId }, objectWithStringDates)
      .then(() => {
        message.success("Event updated!");
      })
      .catch((err) => {
        message.error("There was an error updating this event");
      });
  };

  const handleAddItem: ComponentProps<typeof ItemForm>["onSubmit"] = async (
    item
  ) => {
    await firestore
      .collection(`events/${eventId}/items`)
      .add(item)
      .then((item) => {
        console.log("item log", item);
        message.success("Item added!");
        setAddItemModalVisible(false);
        setItemMode(null);
      })
      .catch((err) => {
        console.log({ err });
        message.error("There was an error creating this auction item");
      });
  };

  const handleUpdateItem: ComponentProps<typeof ItemForm>["onSubmit"] = async (
    item
  ) => {
    await firestore
      .update(
        {
          collection: "events",
          doc: eventId,
          subcollections: [{ collection: "items", doc: selectedItem?.id }],
        },
        item
      )
      .then((item) => {
        console.log("item update log", item);
        message.success("Item updated!");
      })
      .catch((err) => {
        console.log({ err });
        message.error("There was an error updating this auction item");
      });
  };

  const handleEditItem = async (item: AuctionItem) => {
    setSelectedItem(item);
    setItemMode("update");
    setAddItemModalVisible(true);
  };

  const handleDuplicateItem = async (item: AuctionItem) => {
    const { id, ...data } = item;
    await firestore
      .collection(`events/${eventId}/items`)
      .add({ ...data, title: `Copy: ${data.title}` })
      .then((item) => {
        console.log("item duplicate log", item);
        message.success("Item duplicate added!");
        setAddItemModalVisible(false);
        setItemMode(null);
      })
      .catch((err) => {
        console.log({ err });
        message.error("There was an error creating this auction item");
      });
  };

  const handleDeleteItem = async (id: string) => {
    await firestore
      .collection(`events/${eventId}/items`)
      .doc(id)
      .delete()
      .then((item) => {
        message.success("Item deleted!");
        // history.push("/admin/events");
      })
      .catch((err) => {
        console.log({ err });
        message.error("There was an error deleting this item");
      });
  };

  const handleDeleteEvent = async (id: string) => {
    await firestore
      .collection(`events`)
      .doc(id)
      .delete()
      .then((item) => {
        message.success("Event deleted!");
        history.push("/admin/events");
      })
      .catch((err) => {
        console.log({ err });
        message.error("There was an error deleting this event");
      });
  };

  const handleDuplicateEvent = async (event: EventSchema) => {
    const { id, ...details } = event;
    await firestore
      .collection("events")
      .add({
        ...details,
        name: `Copy: ${details.name}`,
        items: [],
        current: false,
      })
      .then(() => {
        message.success("Event duplicated!");
      })
      .catch((err) => {
        console.log({ err });
        message.error("There was an error creating this event");
      });
  };

  return (
    <Col style={{ backgroundColor: "#fff" }}>
      <Tabs
        defaultActiveKey={
          location.pathname.includes("items")
            ? "auction-items"
            : "event-details"
        }
        size="large"
        tabBarExtraContent={
          <Button onClick={() => setAddItemModalVisible(true)}>Add Item</Button>
        }
      >
        <TabPane tab="Event Details" key="event-details">
          <Row>
            <Col xs={24} md={18}>
              <EventForm
                key={eventDetails.id}
                defaultValues={parseAndPrepareTimestamps(eventDetails)}
                onSubmit={handleUpdateEvent}
                buttonText="Update Event"
              />
            </Col>
            <Col
              xs={12}
              md={{ span: 5, offset: 1 }}
              style={{ borderLeft: "1px solid #eee" }}
            >
              <Button
                type="ghost"
                onClick={() => handleDuplicateEvent(eventDetails)}
                icon={<CopyOutlined />}
              />
              <Button
                type="ghost"
                danger
                onClick={() => handleDeleteEvent(eventId)}
                icon={<DeleteOutlined />}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Auction Items" key="auction-items">
          <List
            itemLayout="horizontal"
            dataSource={eventItems}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() => handleEditItem(item)}
                    icon={<EditOutlined />}
                  />,
                  <Button
                    type="link"
                    onClick={() => handleDuplicateItem(item)}
                    icon={<CopyOutlined />}
                  />,
                  <Button
                    type="link"
                    onClick={() => handleDuplicateItem(item)}
                    icon={item.active ? <EyeFilled /> : <EyeInvisibleFilled />}
                  />,
                  <Button
                    type="link"
                    onClick={() => handleDeleteItem(item.id)}
                    disabled={item.active}
                    icon={<DeleteOutlined />}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar size={55} src={item?.images?.[0]?.url} />}
                  title={
                    <>
                      <Button
                        type="link"
                        onClick={() => handleEditItem(item)}
                        style={{ padding: 0 }}
                      >
                        {item.title}
                      </Button>{" "}
                    </>
                  }
                  description={<div>{item.value}</div>}
                />
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="All bids" key="bids">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card>
                <Statistic title="Total bids" value={eventBids.length} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Sum of all bids"
                  value={eventBids
                    .reduce((acc, curr) => {
                      return acc + curr.amount;
                    }, 0)
                    .toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Net earnings"
                  value={eventItems
                    .reduce((acc, curr) => {
                      return acc + (curr?.currentAmount || 0);
                    }, 0)
                    .toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                />
              </Card>
            </Col>
          </Row>
          <List
            bordered
            dataSource={eventBids}
            rowKey="id"
            renderItem={(bid) => (
              <List.Item>
                <Typography.Text mark>
                  {(bid.amount || "NA").toLocaleString("en-us", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography.Text>
                {moment(bid.createdAt?.toDate()).fromNow()}
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
      <Modal
        visible={addItemModalVisible}
        title={selectedItem ? `Update Auction Item` : "Add New Auction Item"}
        onCancel={() => {
          setAddItemModalVisible((prev) => false);
          setSelectedItem((prev) => null);
          setItemMode(null);
        }}
        maskClosable={false}
        footer={null}
      >
        {!addItemModalVisible ? null : itemMode === "create" ? (
          <ItemForm
            onSubmit={selectedItem ? handleUpdateItem : handleAddItem}
            buttonText="Add Item"
          />
        ) : (
          <>
            <ItemForm
              defaultValues={selectedItem ? selectedItem : undefined}
              onSubmit={selectedItem ? handleUpdateItem : handleAddItem}
              buttonText="Update Item"
            />
            <Divider>Item Images</Divider>
            <ImageUploader
              eventId={eventId}
              auctionItemId={selectedItem?.id || ""}
              images={selectedItem?.images}
            />
          </>
        )}
      </Modal>
    </Col>
  );
};

export default EventDetails;
