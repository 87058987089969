import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
  Reducer,
} from "@reduxjs/toolkit";
import { firestoreReducer } from "redux-firestore";
import {
  firebaseReducer,
  getFirebase,
  Listeners,
  Data,
  ReduxFirestoreQuerySetting,
  Dictionary,
  FirebaseReducer,
} from "react-redux-firebase";
import { Timestamp } from "@firebase/firestore-types";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

export const PRESET_DATA_TYPES = {
  categories: [
    { label: "Adventure", value: "adventure" },
    { label: "Cash Pledge", value: "cash-pledge" },
    { label: "Dining", value: "dining" },
    { label: "Educational", value: "educational" },
    { label: "Family", value: "family" },
    { label: "Fitness", value: "fitness" },
    { label: "Gourmet Food", value: "gourmet-food" },
    {
      label: "Hot Air Balloon + Wine Tasting",
      value: "hot-air-balloon-wine-tasting",
    },
    { label: "Personal Item", value: "personal-item" },
    { label: "Tools", value: "tools" },
    { label: "Vacations", value: "vacations" },
  ],
  tags: [
    { label: "Adventure", value: "adventure" },
    { label: "Cash Pledge", value: "cash-pledge" },
    { label: "Dining", value: "dining" },
    { label: "Educational", value: "educational" },
    { label: "Family", value: "family" },
    { label: "Fitness", value: "fitness" },
    { label: "Gourmet Food", value: "gourmet-food" },
    {
      label: "Hot Air Balloon + Wine Tasting",
      value: "hot-air-balloon-wine-tasting",
    },
    { label: "Personal Item", value: "personal-item" },
    { label: "Tools", value: "tools" },
    { label: "Vacations", value: "vacations" },
  ],
};

// TODO type firebase/firestore reducers

declare const entitySymbol: unique symbol;

export type Entity<T> = T & {
  [entitySymbol]: never;
};
export type EntityWithId<T> = T & { id: string };
export type FirestoreData<Schema extends Record<string, any>> = {
  [T in keyof Schema]: Record<
    string,
    Schema[T] extends Entity<infer V> ? V : FirestoreData<Schema[T]>
  >;
};

export type OrderedData<Schema extends Record<string, any>> = {
  [T in keyof Schema]: Schema[T] extends Entity<infer V>
    ? EntityWithId<V>[]
    : OrderedData<EntityWithId<Schema[T]>>[];
};

export interface FirestoreState<Schema extends Record<string, any> = {}> {
  errors: {
    allIds: string[];
    byQuery: any[];
  };
  listeners: Listeners;
  data: FirestoreData<Schema>;
  ordered: OrderedData<Schema>;
  queries: Data<ReduxFirestoreQuerySetting & (Dictionary<any> | any)>;
  status: {
    requested: Dictionary<boolean>;
    requesting: Dictionary<boolean>;
    timestamps: Dictionary<number>;
  };
}

export interface EventSchema {
  id: string;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  current: boolean;
}

export interface Bid {
  id: string;
  amount: number;
  eventId: string;
  createdAt: Timestamp;
}
interface FirestoreSchema {
  events: Entity<EventSchema>;
  items: any;
  [key: string]: Entity<EventSchema>; // it'd be great to have this infer the type of the values...?
}

const extraArgument = {
  getFirebase,
};
export type DefaultThunkExtra = typeof extraArgument;

interface Profile {
  avatarUrl: string;
  displayName: string;
  email: string;
  roles: {
    admin: boolean;
    user: boolean;
    [key: string]: boolean;
  };
  notification_token: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  customerId: string; // unique nanoid() created to be used as the merchant reference in authnet
  lastFour: string; // last 4 of the last added card
  customerProfileId: string; // authnet customer profile id
  customerPaymentProfileIdList: string[]; // collection of authnet payment profiles
}

const rootReducer = combineReducers({
  firebase: firebaseReducer as Reducer<FirebaseReducer.Reducer<Profile, {}>>,
  firestore: firestoreReducer as Reducer<FirestoreState<FirestoreSchema>>,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    thunk: {
      extraArgument,
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
