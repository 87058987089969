import React, { forwardRef } from "react";
import {
  Popover,
  Form as AntForm,
  Popconfirm,
  Button as AntButton,
  InputNumber,
  message,
} from "antd";
import { AuctionItem } from "../auctionsSlice";
import { useForm, Controller } from "react-hook-form";

type AutobidFields = {
  amount: string;
};

const doubleCurrentBidOrActualValue = (item: AuctionItem) => {
  // return double the current bid amount, or the actual value of the item
  if (item.currentAmount) {
    return Math.min(item.currentAmount * 2, item.value || 0);
  }
  return item.value;
};

const AutoBidPopoverButton = ({
  item,
  disabled = false,
}: {
  item: AuctionItem;
  disabled: boolean;
}) => {
  const { handleSubmit, errors, control, getValues } = useForm<AutobidFields>({
    defaultValues: {
      amount: String(doubleCurrentBidOrActualValue(item)),
    },
  });
  // TODO: Implement
  // const handleSetAutobid = handleSubmit(({ amount }) => {});

  const Button = forwardRef((props, ref) => (
    <AntButton size="large" block disabled={disabled} {...props}>
      Auto-bid
    </AntButton>
  ));

  return disabled ? (
    <Button />
  ) : (
    <Popover
      title="Configure Auto-bidding"
      trigger="click"
      content={
        <div>
          <AntForm.Item
            // hasFeedback
            validateStatus={errors.amount ? "error" : "success"}
            label="Max bid"
          >
            <Controller
              as={
                <InputNumber
                  defaultValue={5}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                />
              }
              control={control}
              name="amount"
              rules={{ required: "You must enter an amount" }}
            />
          </AntForm.Item>
          <Popconfirm
            placement="top"
            title={
              <div style={{ maxWidth: 200 }}>
                You are going to automatically bid in increments of $
                {item.minimum_bid_increment} until you reach your maximum bid of{" "}
                {Number(getValues("amount")).toLocaleString("en-us", {
                  style: "currency",
                  currency: "USD",
                })}
                . Is this correct?
              </div>
            }
            onConfirm={() => {
              // TODO: handle autobid
              message.success(`Autobidding has been set on ${item.title}`);
            }}
            okText="Confirm"
            cancelText="Cancel"
            style={{
              maxWidth: 100,
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <Button />
          </Popconfirm>
        </div>
      }
    >
      <Button />
    </Popover>
  );
};

export default AutoBidPopoverButton;
