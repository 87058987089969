import React, { useState } from "react";
import { Button, Input, Col, Card, Row, Form, notification } from "antd";
import { useForm, Controller } from "react-hook-form";
import CreditCard, { ReactCreditCardProps } from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC,
} from "../../utils/payment";
import { createPaymentProfile } from "../billing/billingSlice";
import { useTypedSelector, useAppDispatch } from "../../store";
import { useHistory } from "react-router-dom";

type BillingFormData = {
  first_name: string;
  last_name: string;
  number: string;
  expiry: string;
  cvc: string;
  zip: string;
  address: string;
  state: string;
  city: string;
};

const BillingPage: React.FC = () => {
  const [issuer, setIssuer] = useState<string>("");
  const [focused, setFocused] = useState<ReactCreditCardProps["focused"]>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useTypedSelector((state) => state.firebase.profile);

  const {
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<BillingFormData>({
    defaultValues: {
      number: "",
      cvc: "",
      expiry: "",
      first_name: "",
      last_name: "",
      address: user.address || "",
      city: user.city || "",
      state: user.state || "",
      zip: user.zip || "",
    },
  });

  const formValues = watch();

  const handleCallback = ({ issuer }: { issuer: string }, isValid: boolean) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = (name: ReactCreditCardProps["focused"]) => {
    setFocused(name);
  };
  const handleAddCard = async (cardDetails: BillingFormData) => {
    const resultAction = await dispatch(createPaymentProfile(cardDetails));
    if (createPaymentProfile.fulfilled.match(resultAction)) {
      const description = user.customerProfileId ? (
        `Your card was updated`
      ) : (
        <div>
          Your card was added successfully. You can now participate in the
          auction.
          <br />
          <br />
          <Button
            type="link"
            onClick={() => {
              history.push("/");
              notification.destroy();
            }}
            style={{ padding: 0 }}
          >
            View Items
          </Button>
        </div>
      );
      notification.success({
        message: "Success",
        description,
      });
      reset();
    } else {
      console.log("Add card error:", resultAction);

      notification.error({
        message: "Error",
        description: (
          <div>
            There was an error adding your card. Please try again.
            <br />
            <br />
            {(resultAction.payload as any)?.error?.message
              .map(
                (msg: { code: string; text: string }) =>
                  `${msg.code}: ${msg.text}`
              )
              .toString()}
          </div>
        ),
      });
    }
  };

  return (
    <Col xs={24} lg={18}>
      <Card title="Manage your billing" style={{ marginBottom: 15 }}>
        {user.lastFour && user.customerProfileId
          ? `Your current card on file ends with ${user.lastFour}.`
          : `You currently have no payment method on file. Please add one so you can
        start bidding.`}
      </Card>
      <Card title="Add a new payment method">
        <CreditCard
          number={formValues.number}
          name={`${formValues.first_name} ${formValues.last_name}`}
          expiry={formValues.expiry}
          cvc={formValues.cvc}
          focused={focused}
          callback={handleCallback}
        />
        <form
          onSubmit={handleSubmit(handleAddCard)}
          className="ant-form ant-form-vertical"
        >
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <Form.Item
                label="First Name"
                validateStatus={errors.first_name ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  as={<Input onFocus={() => handleInputFocus("name")} />}
                  control={control}
                  name="first_name"
                  rules={{
                    required:
                      "Enter your first name as it appears on your card",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name"
                validateStatus={errors.last_name ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  as={<Input onFocus={() => handleInputFocus("name")} />}
                  control={control}
                  name="last_name"
                  rules={{
                    required: "Enter your last name as it appears on your card",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                label="Card Number"
                // labelAlign="left"
                validateStatus={errors.number ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  control={control}
                  name="number"
                  rules={{ required: "You must enter a valid card number" }}
                  render={({ value }) => (
                    <Input
                      // placeholder="Card Number"
                      value={value}
                      onFocus={() => handleInputFocus("number")}
                      onChange={({ target }) => {
                        setValue(
                          "number",
                          formatCreditCardNumber(target.value)
                        );
                      }}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={8}>
              <Form.Item
                label="Expr. Date"
                validateStatus={errors.expiry ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      // placeholder="expiry"
                      onFocus={() => handleInputFocus("expiry")}
                      value={value}
                      onChange={({ target }) => {
                        setValue("expiry", formatExpirationDate(target.value));
                      }}
                      pattern="\d\d/\d\d"
                    />
                  )}
                  control={control}
                  name="expiry"
                  rules={{ required: "Enter a valid expiry date" }}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                label="CVC"
                validateStatus={errors.cvc ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      // placeholder="CVC"
                      onFocus={() => handleInputFocus("cvc")}
                      value={value}
                      onChange={({ target }) => {
                        setValue("cvc", formatCVC(target.value));
                      }}
                      pattern="\d{3,4}"
                      type="tel"
                    />
                  )}
                  control={control}
                  name="cvc"
                  rules={{ required: "Enter a valid CVV/CVC" }}
                />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                label="Zip"
                validateStatus={errors.zip ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="Zip Code"
                      value={value}
                      onChange={({ target }) => {
                        setValue("zip", target.value);
                      }}
                      pattern="\d{5}"
                      type="tel"
                    />
                  )}
                  control={control}
                  name="zip"
                  rules={{ required: "Enter a valid zip code" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                label="Address"
                validateStatus={errors.address ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="Address"
                      value={value}
                      onChange={({ target }) => {
                        setValue("address", target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="address"
                  rules={{ required: "Enter your billing address" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={16}>
              <Form.Item
                label="City"
                validateStatus={errors.city ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="City"
                      value={value}
                      onChange={({ target }) => {
                        setValue("city", target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="city"
                  rules={{ required: "Enter a city" }}
                />
              </Form.Item>
            </Col>

            <Col xs={4}>
              <Form.Item
                label="State"
                validateStatus={errors.state ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="State"
                      value={value}
                      onChange={({ target }) => {
                        setValue("state", target.value);
                      }}
                      pattern="\w{2}"
                    />
                  )}
                  control={control}
                  name="state"
                  rules={{ required: "Enter a valid state abbreviation" }}
                />
              </Form.Item>
            </Col> 
            
          </Row>*/}

          <input type="hidden" name="issuer" value={issuer} />
          <div className="form-actions">
            <Button
              type="primary"
              block
              htmlType="submit"
              size="large"
              loading={isSubmitting}
            >
              Add payment method
            </Button>
          </div>
        </form>
      </Card>
    </Col>
  );
};

export default BillingPage;
