import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Select,
  Divider,
  Input,
  Modal,
  message,
  Empty,
  Alert,
} from "antd";
import {
  useAppDispatch,
  useTypedSelector,
  PRESET_DATA_TYPES,
} from "../../store";
import { makeBid, AuctionItem } from "./auctionsSlice";
import { useFirestoreConnect, isEmpty } from "react-redux-firebase";
import AuctionItemDisplay from "./AuctionItemDisplay";
import { FileImageTwoTone, MehOutlined } from "@ant-design/icons";
import AutoBidPopoverButton from "./components/AutoBidPopoverButton";
import Title from "antd/lib/typography/Title";
import BidPopoverButton from "./components/BidPopoverButton";
import { useHistory } from "react-router-dom";

const { Search } = Input;

const AuctionIndex = ({ eventId }: { eventId: string }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [visibleItemId, setVisibleItemId] = useState("");
  const [filter, setFilter] = useState<null | string>(null);
  const [categoryFilter, setCategoryFilter] = useState<null | string>(null);
  useFirestoreConnect([
    {
      collection: `events`,
      doc: eventId,
      subcollections: [{ collection: "items" }],
      storeAs: "items",
    },
  ]);

  const auctionItems: AuctionItem[] = useTypedSelector(
    (state) => state.firestore.ordered.items || []
  );
  const auth = useTypedSelector((state) => state.firebase.auth);
  const user = useTypedSelector((state) => state.firebase.profile);

  const isAuthenticated = !isEmpty(auth);
  const canPlaceBids = isAuthenticated && user.customerProfileId;

  const filteredAuctionItems = auctionItems.filter((item) => {
    let match = true;
    if (filter) {
      match = item.title.toLowerCase().includes(filter.toLowerCase());
    } else if (categoryFilter) {
      match = item.category?.some((category) =>
        category.toLowerCase().includes(categoryFilter.toLowerCase())
      );
    }
    return match;
  });

  return (
    <div className="site-card-wrapper">
      <Row gutter={[16, 16]}>
        {!isAuthenticated ? (
          <Col xs={24}>
            <Alert
              type="warning"
              message="Please sign in or create an account"
              showIcon
              description={
                <div>
                  You need to be signed in to participate. Would you like to{" "}
                  <Button
                    onClick={() => history.push("/login")}
                    type="link"
                    style={{ margin: 0, padding: 0 }}
                  >
                    sign in now
                  </Button>{" "}
                  or{" "}
                  <Button
                    onClick={() => history.push("/register")}
                    type="link"
                    style={{ margin: 0, padding: 0 }}
                  >
                    create an account
                  </Button>
                  ?
                </div>
              }
            />
          </Col>
        ) : !canPlaceBids ? (
          <Col xs={24}>
            <Alert
              type="warning"
              showIcon
              message={
                <div>
                  Thank you for registering for the auction! In order to place
                  bids, please{" "}
                  <Button
                    onClick={() => history.push("/settings/billing")}
                    type="link"
                    style={{ margin: 0, padding: 0 }}
                  >
                    set up your billing profile
                  </Button>
                  .
                </div>
              }
            />
          </Col>
        ) : null}

        <Col xs={12} md={6}>
          <Select
            style={{ width: "100%" }}
            placeholder="All Categories"
            allowClear
            options={PRESET_DATA_TYPES.categories}
            onSelect={(value) => {
              setCategoryFilter(value as string);
            }}
            {...(categoryFilter ? { value: categoryFilter } : {})}
            onChange={(value) => setCategoryFilter((value || "") as string)}
          ></Select>
        </Col>
        <Col xs={12} md={6}>
          <Select
            placeholder="All Auction Types"
            options={[
              { label: "Silent Auctions", value: "silent" },
              { label: "Live Auction", value: "live" },
              { label: "Fixed Price Item(s)", value: "fixed-price" },
            ]}
            style={{ width: "100%" }}
            allowClear
          />
        </Col>

        <Col xs={24} md={{ span: 6, offset: 6 }} style={{ textAlign: "right" }}>
          <Search
            style={{ width: "100%" }}
            placeholder="Search"
            onChange={(event) => setFilter(event?.currentTarget.value)}
            onSearch={(text) => setFilter(text)}
            {...(filter ? { value: filter } : {})}
            allowClear
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        {filteredAuctionItems.length === 0 ? (
          <Col style={{ margin: "0 auto", textAlign: "center" }} span={24}>
            <Empty
              style={{ height: 180 }}
              image={<MehOutlined style={{ fontSize: 140 }} />}
              description=""
            />
            <Title level={4}>
              Oh no! It looks like there are no items matching the given
              criteria.
              <br />
              Would you like to clear your filters and show all items?
            </Title>

            <Button
              onClick={() => {
                setFilter(null);
                setCategoryFilter(null);
              }}
              type="primary"
              size="large"
              style={{ margin: 30 }}
            >
              Show all items
            </Button>
          </Col>
        ) : (
          filteredAuctionItems.map((item) => (
            <Col md={12} xs={24} lg={6} key={item.id}>
              <Card bordered={false}>
                <div style={{ height: 200, overflow: "hidden" }}>
                  {item.images?.length ? (
                    <img
                      src={item.images[0].url}
                      alt={item.images[0].name}
                      style={{ maxWidth: "100%" }}
                    />
                  ) : (
                    <Empty
                      description=""
                      image={
                        <FileImageTwoTone
                          style={{ fontSize: 90 }}
                          twoToneColor="#eee"
                        />
                      }
                    />
                  )}
                </div>
                <h4>{item.title}</h4>
                <Row>
                  <Col span={12}>Value</Col>
                  <Col span={12}>
                    {(item.value || "").toLocaleString("en-us", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>Current Bid</Col>
                  <Col span={12}>
                    {(
                      item.currentAmount ||
                      item.starting_bid ||
                      ""
                    ).toLocaleString("en-us", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={12}>Current Winner</Col>
                  <Col span={12}>
                    {item.currentWinner === userId
                      ? "You are currently winning"
                      : "Someone else is winning, bid now!"}
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col span={12}>Starred by</Col>
                  <Col span={12}>{item.stars?.length}</Col>
                </Row> */}
                <div style={{ textAlign: "center", padding: 20 }}>
                  <div style={{ padding: "0px 0px 20px" }}>
                    <Button
                      size="large"
                      onClick={() => setVisibleItemId(item.id)}
                      type="link"
                    >
                      View Details
                    </Button>
                  </div>

                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <BidPopoverButton
                        item={item}
                        disabled={!canPlaceBids}
                        onBid={async () => {
                          const resultAction = await dispatch(
                            makeBid({
                              event_id: eventId,
                              item_id: item.id,
                              amount:
                                (item.currentAmount || item.starting_bid) +
                                item.minimum_bid_increment,
                            })
                          );
                          if (makeBid.fulfilled.match(resultAction)) {
                            message.success("Your bid was placed!");
                          } else {
                            if (resultAction.payload) {
                              const knownMessage = resultAction.payload.message;
                              message.error(
                                knownMessage
                                  ? knownMessage
                                  : "There was an error, try again."
                              );
                            }
                          }
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <AutoBidPopoverButton
                        item={item}
                        disabled={!canPlaceBids}
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))
        )}
      </Row>
      <Modal
        visible={Boolean(visibleItemId)}
        onOk={() => setVisibleItemId("")}
        onCancel={() => setVisibleItemId("")}
        cancelText="Close"
        width={600}
      >
        {visibleItemId ? <AuctionItemDisplay id={visibleItemId} /> : null}
      </Modal>
    </div>
  );
};

export default AuctionIndex;
