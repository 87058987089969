import React, { useState, ComponentProps } from "react";
import { Col, Card, List, Avatar, Button, message, Tag } from "antd";
import { useFirestore } from "react-redux-firebase";
import { useTypedSelector } from "../../store";
import { useHistory } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import EventForm from "./components/event-form";
import moment, { isMoment } from "moment";
import Search from "antd/lib/input/Search";

const EventManager: React.FC = () => {
  const [filter, setFilter] = useState<string | null>(null);
  const [addEventModalVisible, setAddEventModalVisible] = useState(false);

  const events = useTypedSelector(
    (state) => state.firestore.ordered.events || []
  );
  const history = useHistory();
  const firestore = useFirestore();

  const handleCreateEvent: ComponentProps<
    typeof EventForm
  >["onSubmit"] = async (doc) => {
    console.info(doc);
    // convert moment instances to formatted strings
    const objectWithStringDates = Object.entries(doc).reduce(
      (acc, [key, val]) => {
        if (isMoment(val)) {
          acc[key] = val.toString();
        } else {
          acc[key] = val;
        }
        return acc;
      },
      {} as typeof doc & { [key: string]: any }
    );
    await firestore
      .collection("events")
      .add({ ...objectWithStringDates, items: [] })
      .then(() => {
        message.success("Event created!");
        setAddEventModalVisible(false);
      })
      .catch((err) => {
        console.log({ err });
        message.error("There was an error creating this event");
      });
  };

  const filteredEvents = events.filter((evt) =>
    filter ? evt.name.toLowerCase().includes(filter?.toLowerCase()) : true
  );

  return (
    <Col>
      <Card title="Current Event" style={{ marginBottom: 20 }}>
        <List
          itemLayout="horizontal"
          dataSource={events.filter((event) => event.current)}
          renderItem={(event) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => history.push(`/admin/events/${event.id}`)}
                >
                  Manage
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar />}
                title={
                  <>
                    <Button
                      type="link"
                      onClick={() => history.push(`/admin/events/${event.id}`)}
                      style={{ padding: 0 }}
                    >
                      {event.name}
                    </Button>{" "}
                    <Tag color="success">
                      Starts in {moment(event.start_date).fromNow()}
                    </Tag>
                  </>
                }
                description={
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: event.description || "Waiting on a description",
                      }}
                    />

                    {/* Auction Items: 0 <Divider type="vertical" /> Bids: 0 */}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Card>
      <Card
        title="Manage Your Events"
        extra={
          <Button type="primary" onClick={() => setAddEventModalVisible(true)}>
            Add new event
          </Button>
        }
      >
        <Col xs={24} style={{ textAlign: "right" }}>
          <Search
            style={{ width: "100%" }}
            placeholder="Search"
            onChange={(event) => setFilter(event?.currentTarget.value)}
            onSearch={(text) => setFilter(text)}
          />
        </Col>
        <List
          itemLayout="horizontal"
          dataSource={filteredEvents}
          renderItem={(event) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => history.push(`/admin/events/${event.id}`)}
                >
                  Manage
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar />}
                title={
                  <Button
                    type="link"
                    onClick={() => history.push(`/admin/events/${event.id}`)}
                    style={{ padding: 0 }}
                  >
                    {event.name}
                  </Button>
                }
                description={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: event.description || "Waiting on a description",
                    }}
                  />
                }
              />
            </List.Item>
          )}
        />
      </Card>
      {addEventModalVisible && (
        <Modal
          visible={addEventModalVisible}
          title="Add New Event"
          onCancel={() => setAddEventModalVisible(false)}
          maskClosable={false}
          footer={null}
        >
          <EventForm onSubmit={handleCreateEvent} buttonText="Create Event" />
        </Modal>
      )}
    </Col>
  );
};

export default EventManager;
