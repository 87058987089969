import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Input, Collapse } from "antd";

const { Panel } = Collapse;

const HtmlEditor = ({
  content = "",
  onChange,
}: {
  // Content coming in should be html, but a text string is also fine
  content: string;
  // Returns html
  onChange?: (html: string) => any;
}) => {
  const getInitialContent = (content: string) => {
    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return contentState;
  };

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(getInitialContent(content))
  );

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    return (
      onChange &&
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    );
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        editorStyle={{ border: "1px solid #eee", padding: 20 }}
        wrapperClassName="html-editor-wrapper"
        editorClassName="html-editor"
        onEditorStateChange={onEditorStateChange}
      />

      <Collapse>
        <Panel header="HTML Preview" key="1">
          <Input.TextArea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            autoSize={{ minRows: 20 }}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default HtmlEditor;
