import React, { useEffect } from "react";
import "./App.less";
import { Route, Switch } from "react-router-dom";
import AuctionIndex from "./features/auction/AuctionIndex";
import { Layout } from "antd";
import LoginPage from "./features/auth/LoginPage";
import RegisterPage from "./features/auth/RegisterPage";
import PrivateRoute from "./utils/PrivateRoute";
import SettingsContainer from "./features/auth/SettingsContainer";
import LiveViewTV from "./features/live/LiveViewTV";
import AdminContainer from "./features/admin/AdminContainer";
import HeaderMobile from "./features/app/HeaderMobile";
import { useTypedSelector } from "./store";
import { isEmpty, useFirebase, isLoaded } from "react-redux-firebase";
import { messaging } from ".";

const { Footer, Content } = Layout;

function App() {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const user = useTypedSelector((state) => state.firebase.profile);
  const firebase = useFirebase();

  useEffect(() => {
    if (isEmpty(auth) || !isLoaded(user)) {
      return;
    }

    messaging
      .requestPermission()
      .then(async function () {
        const token = await (await messaging.getToken()).trim();
        if (token !== user.notification_token) {
          await firebase.updateProfile({
            notification_token: token,
          });
        }
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });

    navigator.serviceWorker.addEventListener("message", (message) =>
      console.log(message)
    );

    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then(async (refreshedToken) => {
          console.log("Token refreshed.", refreshedToken);
          if (refreshedToken !== user.notification_token) {
            await firebase.updateProfile({
              notification_token: refreshedToken,
            });
          }
        })
        .catch((err) => {
          console.log("Unable to retrieve refreshed token ", err);
          // showToken('Unable to retrieve refreshed token ', err);
        });
    });
  }, [auth, user, firebase]);

  return (
    <div className="App">
      <Layout className="layout">
        <HeaderMobile isAuthenticated={!isEmpty(auth)} />
        <Content style={{ padding: "40px 50px", textAlign: "left" }}>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/live" component={LiveViewTV} />
            <PrivateRoute path="/admin" roles={["admin"]}>
              <AdminContainer />
            </PrivateRoute>

            <PrivateRoute path="/settings">
              <SettingsContainer />
            </PrivateRoute>

            <Route path="/">
              <AuctionIndex eventId="yndMazIvnf53Q5FqP6om" />
            </Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          &copy; {new Date().getFullYear()} Fallbrook Chamber of Commerce
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
