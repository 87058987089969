import React from "react";
import GoogleButton from "react-google-button";
import { useFirebase } from "react-redux-firebase";
import { useHistory, Link } from "react-router-dom";
import { Row, Col, Card, Form, Input, Button, message } from "antd";
import { useForm, Controller } from "react-hook-form";

type LoginValues = {
  email: string;
  password: string;
};
const LoginPage = () => {
  const firebase = useFirebase();
  const history = useHistory();

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<LoginValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const googleLogin = () =>
    firebase
      .login({ provider: "google", type: "popup" })
      .then((result) => history.push("/"))
      .catch((err) => message.error(err.message));

  const handleSignIn = async (credentials: {
    email: string;
    password: string;
  }) =>
    firebase
      .login(credentials)
      .then((result) => {
        setTimeout(() => history.push("/"), 100); // set a timeout to make sure the store is updated for a redirect
      })
      .catch((err) => message.error(err.message));

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={{ span: 8, offset: 8 }} xs={24}>
        <h1 style={{ textAlign: "center" }}>Sign in to your account</h1>
        <Card>
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Row gutter={[16, 16]}>
              <Col xs={24} style={{ textAlign: "left" }}>
                <Form.Item
                  label="Email"
                  labelCol={formItemLayout.labelCol}
                  labelAlign="left"
                  wrapperCol={formItemLayout.wrapperCol}
                  validateStatus={errors.email ? "error" : "success"}
                >
                  <Controller
                    as={
                      <Input
                        placeholder="your@email.com"
                        autoComplete="email"
                      />
                    }
                    control={control}
                    name="email"
                    rules={{ required: "You must enter a valid email" }}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  labelCol={formItemLayout.labelCol}
                  labelAlign="left"
                  validateStatus={errors.password ? "error" : "success"}
                  wrapperCol={formItemLayout.wrapperCol}
                >
                  <Controller
                    as={
                      <Input.Password
                        placeholder="Your Password"
                        autoComplete="current-password"
                      />
                    }
                    control={control}
                    name="password"
                    rules={{ required: "You must enter a password" }}
                  />
                  <div style={{ textAlign: "right" }}>
                    <Button type="link" style={{ margin: 0, padding: 0 }}>
                      Forgot your password?
                    </Button>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} style={{ textAlign: "center" }}>
                <Button
                  loading={isSubmitting}
                  htmlType="submit"
                  type="primary"
                  size="large"
                  block
                >
                  Sign in
                </Button>
              </Col>
            </Row>
          </form>
          <div style={{ textAlign: "center", padding: "15px 0" }}>or</div>
          <div style={{ textAlign: "center" }}>
            <GoogleButton
              onClick={googleLogin}
              data-test-id="google-auth-button"
              style={{ margin: "0 auto" }}
              label="Sign in with Google"
            />
          </div>

          <div style={{ textAlign: "center", marginTop: 30 }}>
            Need an account? <Link to="/register">Sign up here</Link>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginPage;
