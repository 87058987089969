export const {
  REACT_APP_FIREBASE_APIKEY: FIREBASE_APIKEY,
  REACT_APP_FIREBASE_AUTHDOMAIN: FIREBASE_AUTHDOMAIN,
  REACT_APP_FIREBASE_DATABASEURL: FIREBASE_DATABASEURL,
  REACT_APP_FIREBASE_PROJECTID: FIREBASE_PROJECTID,
  REACT_APP_FIREBASE_STORAGEBUCKET: FIREBASE_STORAGEBUCKET,
  REACT_APP_FIREBASE_MESSAGINGSENDERID: FIREBASE_MESSAGINGSENDERID,
  REACT_APP_FIREBASE_APPID: FIREBASE_APPID,
  REACT_APP_FIREBASE_MEASUREMENTID: FIREBASE_MEASUREMENTID,
  REACT_APP_FIREBASE_MESSAGING_PUBLIC_VAPID_KEY: FIREBASE_MESSAGING_PUBLIC_VAPID_KEY,
  REACT_APP_FIREBASE_FUNCTIONS_BASE_URL: FIREBASE_FUNCTIONS_BASE_URL,
} = process.env;
