import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Form as AntForm, Input, Button, DatePicker, Checkbox } from "antd";
import { UnpackNestedValue } from "react-hook-form/dist/types/form";
import { DeepPartial } from "react-hook-form/dist/types/utils";
import moment, { Moment } from "moment";
import HtmlEditor from "./html-editor";

export type EventFormData = {
  name: string;
  description: string;
  start_date: Moment;
  end_date: Moment;
  current: boolean;
};

const EventForm: React.FC<{
  defaultValues?: UnpackNestedValue<DeepPartial<EventFormData>>;
  onSubmit: SubmitHandler<EventFormData>;
  buttonText?: string;
}> = ({
  onSubmit,
  defaultValues = {
    name: "",
    description: "",
    start_date: moment(),
    end_date: moment(),
    current: false,
  },
  buttonText = "Create Event",
}) => {
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting, isDirty },
  } = useForm<EventFormData>({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AntForm.Item
        hasFeedback
        validateStatus={errors.name ? "error" : "success"}
        label="Name"
      >
        <Controller
          as={<Input />}
          control={control}
          name="name"
          rules={{ required: "You must enter an event name" }}
        />
      </AntForm.Item>

      <AntForm.Item
        hasFeedback
        validateStatus={errors.start_date ? "error" : "success"}
        label="Start Date"
      >
        <Controller
          as={
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="MM-DD-YYYY HH:mm"
            />
          }
          control={control}
          name="start_date"
          rules={{ required: true }}
          onChange={([selected]: any) => ({ value: selected })}
        />
      </AntForm.Item>
      <AntForm.Item
        hasFeedback
        validateStatus={errors.end_date ? "error" : "success"}
        label="End Date"
      >
        <Controller
          as={
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="MM-DD-YYYY HH:mm"
            />
          }
          control={control}
          name="end_date"
          rules={{ required: true }}
          onChange={([selected]: any) => ({ value: selected })}
        />
      </AntForm.Item>
      <AntForm.Item
        hasFeedback
        validateStatus={errors.current ? "error" : "success"}
        label="Mark as current"
      >
        <Controller
          render={({ value, onChange }) => (
            <Checkbox
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
            />
          )}
          control={control}
          name="current"
        />
      </AntForm.Item>

      <AntForm.Item label="Description">
        <Controller
          name="description"
          hasFeedback
          validateStatus={errors.description ? "error" : "success"}
          control={control}
          rules={{ required: true }}
          render={({ value, onChange }) => (
            <HtmlEditor content={value} onChange={onChange} />
          )}
        />
      </AntForm.Item>

      <Button
        htmlType="submit"
        type="primary"
        size="large"
        loading={isSubmitting}
        disabled={!isDirty}
      >
        {buttonText}
      </Button>
    </form>
  );
};

export default EventForm;
