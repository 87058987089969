import React, { useState } from "react";
import { Row, Col, Menu, Spin } from "antd";
import { MenuProps } from "antd/lib/menu";
import {
  useLocation,
  useRouteMatch,
  useHistory,
  Switch,
  Route,
} from "react-router-dom";
import EventManager from "./EventManager";
import EventDetails from "./event-details";
import { useFirestoreConnect } from "react-redux-firebase";

const AdminContainer: React.FC = ({ children }) => {
  const [mode, setMode] = React.useState<MenuProps["mode"]>("inline");
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  const [selectKey, setSelectKey] = useState(
    location.pathname.replace(`${match.path}/`, "")
  );

  const menuMap: { [key: string]: string } = {
    events: "Events",
    // items: "Auction Items",
  };

  useFirestoreConnect([
    {
      collection: `events`,
      storeAs: "events",
    },
  ]);

  const handleResize = () => {
    requestAnimationFrame(() => {
      let mode: MenuProps["mode"] = "inline";
      if (window.innerWidth > 741 && mode !== "inline") {
        mode = "inline";
      }
      if (window.innerWidth < 740 && window.innerWidth > 480) {
        mode = "horizontal";
      }
      if (window.innerWidth < 479 && mode !== "inline") {
        mode = "inline";
      }
      setMode(mode);
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={6}>
        <Menu
          mode={mode}
          selectedKeys={[selectKey]}
          onClick={({ key }) => {
            setSelectKey(String(key));
            history.push(`/admin/${key}`);
          }}
        >
          {Object.keys(menuMap).map((item) => (
            <Menu.Item key={item}>{menuMap[item]}</Menu.Item>
          ))}
        </Menu>
      </Col>
      <Col xs={24} md={16}>
        {children}
        <Switch>
          <React.Suspense fallback={<Spin />}>
            <Route
              exact
              key="events"
              path={["/admin", "/admin/events"]}
              component={EventManager}
            />
            <Route
              exact
              key="event"
              path="/admin/events/:eventId"
              component={EventDetails}
            />
            <Route
              exact
              key="event-items"
              path="/admin/events/:eventId/items"
              component={EventDetails}
            />
          </React.Suspense>
        </Switch>
      </Col>
    </Row>
  );
};

export default AdminContainer;
