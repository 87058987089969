import * as React from "react";
import {
  Button,
  Input,
  Form as AntForm,
  Col,
  Card,
  message,
  Form,
  Row,
} from "antd";
import { useForm, Controller } from "react-hook-form";
import { useTypedSelector } from "../../store";
import { useFirebase } from "react-redux-firebase";

type ProfileFormData = {
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

const ProfilePage: React.FC = () => {
  const user = useTypedSelector((state) => state.firebase.profile);
  const firebase = useFirebase();

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm<ProfileFormData>({
    defaultValues: {
      first_name: user.displayName?.split(" ")[0] || "",
      last_name: user.displayName?.split(" ")[1] || "",
      email: user.email || "",
      address: user.address || "",
      city: user.city || "",
      state: user.state || "",
      zip: user.zip || "",
    },
  });

  const handleUpdateProfile = handleSubmit(
    async ({ first_name, last_name, address, state, city, zip }) => {
      try {
        await firebase.updateProfile({
          displayName: `${first_name} ${last_name}`,
          address,
          state,
          city,
          zip,
        });
        message.success("Your information was saved");
      } catch (err) {
        console.log(err);
        message.error(`There was an error saving your information.`);
      }
    }
  );

  return (
    <Col xs={24}>
      <Card title="Your information">
        <form
          onSubmit={handleUpdateProfile}
          className="ant-form ant-form-vertical"
        >
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <AntForm.Item
                validateStatus={errors.first_name ? "error" : "success"}
                label="First name"
              >
                <Controller
                  as={<Input />}
                  control={control}
                  name="first_name"
                  rules={{ required: true }}
                />
              </AntForm.Item>
            </Col>
            <Col xs={12}>
              <AntForm.Item
                validateStatus={errors.last_name ? "error" : "success"}
                label="Last name"
              >
                <Controller
                  as={<Input />}
                  control={control}
                  name="last_name"
                  rules={{ required: true }}
                />
              </AntForm.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                label="Address"
                validateStatus={errors.address ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="Address"
                      value={value}
                      onChange={({ target }) => {
                        setValue("address", target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="address"
                  rules={{ required: "Enter your billing address" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={14}>
              <Form.Item
                label="City"
                validateStatus={errors.city ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="City"
                      value={value}
                      onChange={({ target }) => {
                        setValue("city", target.value);
                      }}
                    />
                  )}
                  control={control}
                  name="city"
                  rules={{ required: "Enter a city" }}
                />
              </Form.Item>
            </Col>

            <Col xs={4}>
              <Form.Item
                label="State"
                validateStatus={errors.state ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="State"
                      value={value}
                      onChange={({ target }) => {
                        setValue("state", target.value);
                      }}
                      pattern="\w{2}"
                    />
                  )}
                  control={control}
                  name="state"
                  rules={{ required: "Enter a valid state abbreviation" }}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Zip"
                validateStatus={errors.zip ? "error" : "success"}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  render={({ value }) => (
                    <Input
                      placeholder="Zip Code"
                      value={value}
                      onChange={({ target }) => {
                        setValue("zip", target.value);
                      }}
                      pattern="\d{5}"
                      type="tel"
                    />
                  )}
                  control={control}
                  name="zip"
                  rules={{ required: "Enter a valid zip code" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="submit"
            type="primary"
            size="large"
            loading={isSubmitting}
          >
            Save changes
          </Button>
        </form>
      </Card>
    </Col>
  );
};

export default ProfilePage;
