import * as React from "react";
import { Spin, Row, Menu, Col } from "antd";
import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import SecurityPage from "./SecurityPage";
import ProfilePage from "./ProfilePage";
import BillingPage from "./BillingPage";

import { MenuProps } from "antd/lib/menu";

const SettingsContainer: React.FC = ({ children }) => {
  const [mode, setMode] = React.useState<MenuProps["mode"]>("inline");
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  const [selectKey, setSelectKey] = React.useState(
    location.pathname.replace(`${match.path}/`, "")
  );

  React.useEffect(() => {
    setSelectKey(location.pathname.replace(`${match.path}/`, ""));
  }, [location.pathname, match.path]);

  const menuMap: { [key: string]: string } = {
    profile: "Basic Settings",
    security: "Security",
    billing: "Billing",
  };

  const handleResize = () => {
    requestAnimationFrame(() => {
      let mode: MenuProps["mode"] = "inline";
      if (window.innerWidth > 741 && mode !== "inline") {
        mode = "inline";
      }
      if (window.innerWidth < 740 && window.innerWidth > 480) {
        mode = "horizontal";
      }
      if (window.innerWidth < 479 && mode !== "inline") {
        mode = "inline";
      }
      setMode(mode);
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={6}>
        <Menu
          mode={mode}
          selectedKeys={[selectKey]}
          onClick={({ key }) => {
            setSelectKey(String(key));
            history.push(`/settings/${key}`);
          }}
        >
          {Object.keys(menuMap).map((item) => (
            <Menu.Item key={item}>{menuMap[item]}</Menu.Item>
          ))}
        </Menu>
      </Col>
      <Col xs={24} md={18} lg={16}>
        {children}
        <Switch>
          <React.Suspense fallback={<Spin />}>
            <Route
              exact
              key="base"
              path="/settings/profile"
              component={ProfilePage}
            />
            <Route
              exact
              key="security"
              path="/settings/security"
              component={SecurityPage}
            />
            <Route
              exact
              key="billing"
              path="/settings/billing"
              component={BillingPage}
            />
          </React.Suspense>
        </Switch>
      </Col>
    </Row>
  );
};

export default SettingsContainer;
