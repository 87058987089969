import React from "react";
import { Progress } from "antd";
import useWindowSize from "react-use/lib/useWindowSize";

import Confetti from "react-confetti";
import { selectTotalDonations } from "../auction/auctionsSlice";
import { useTypedSelector } from "../../store";
import { useFirestoreConnect } from "react-redux-firebase";

// TODO: every 10k, or certain % of goals
const goal = 2600;
const LiveViewTV = () => {
  const totalDonations = useTypedSelector(selectTotalDonations);
  const { width, height } = useWindowSize();

  useFirestoreConnect([
    {
      collection: `events`,
      doc: "dsZ3izqOoiXXJ4iSh0fc",
      subcollections: [{ collection: "items" }],
      storeAs: "items",
    },
  ]);

  return (
    <div style={{ minHeight: 400 }}>
      {totalDonations >= goal ? (
        <Confetti width={width} height={height} />
      ) : null}
      <h3>Total donations</h3>
      <div>
        {totalDonations.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </div>
      <div style={{ transform: "rotate(-90deg)", width: 400, marginTop: 100 }}>
        <Progress
          strokeColor={{
            from: "#108ee9",
            to: "#87d068",
          }}
          percent={Math.round((totalDonations / goal) * 100)}
          status="active"
          strokeWidth={100}
        />
      </div>

      {/* <div style={{ transform: "rotate(90deg)" }}>
        <Progress
          type="circle"
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          percent={Math.round((totalDonations / goal) * 100)}
        />
      </div> */}
      {/* <Button
          onClick={() => {
            dispatch(incrementByAmount({ amount: 1000 }));
            setHasDonation(true);
            setTimeout(() => setHasDonation(false), 3000);
          }}
        >
          Add donation!
        </Button> */}
    </div>
  );
};

export default LiveViewTV;
