import { createAsyncThunk } from "@reduxjs/toolkit";
import { DefaultThunkExtra } from "../../store";
import axios from "axios";
import { FIREBASE_FUNCTIONS_BASE_URL } from "../../config";

export const createPaymentProfile = createAsyncThunk<
  {
    name: string;
    number: string;
    cvc: string;
    zip: string;
    expiry: string;
  },
  any,
  {
    extra: DefaultThunkExtra;
    rejectValue: {
      message: string;
    };
  }
>(
  "billing/createPaymentProfile",
  async (
    { first_name, last_name, number, cvc, zip, expiry },
    { rejectWithValue, extra: { getFirebase } }: any
  ) => {
    const firebase = getFirebase();
    const authToken = await firebase.auth().currentUser.getIdToken();

    const baseUrl = window.location.host.includes("localhost")
      ? "http://localhost:5001/fallbrook-chamber-auction/us-central1"
      : FIREBASE_FUNCTIONS_BASE_URL;

    try {
      const { data } = await axios.post<{
        success: boolean;
        [key: string]: any;
      }>(
        `${baseUrl}/paymentProfiles`,
        {
          cardDetails: { first_name, last_name, number, cvc, zip, expiry },
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!data.success) {
        return rejectWithValue(data);
      }

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data) as any;
    }
  }
);
