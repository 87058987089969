/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState } from "react";
import { Menu, Dropdown, Drawer, Button } from "antd";
import logo from "./logo.png";
// import RightMenu from "./RightMenu";
import { useHistory } from "react-router-dom";
import { MenuOutlined, BellOutlined, CloseOutlined } from "@ant-design/icons";
import { useFirebase } from "react-redux-firebase";
import { useTypedSelector } from "../../store";

const colors: { [key: string]: string } = {
  charcoal: "#848484",
  midCharcoal: "#BCBCBC",
  lightCharcoal: "#E2E2E2",
  fallbrookGreen: "rgb(29, 165, 122)",
};

const mobileNavContainerCSS = css`
  margin: 0;
  background: white;
  top: 0px;
  z-index: 10;
  width: 100%;
`;

const mobileNavContainerBoxShadow = css`
  ${mobileNavContainerCSS};
  box-shadow: 0px 1px 8px 1px rgba(133, 133, 133, 0.2);
`;

const mobileNavCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 70px;
  padding: 0 20px;
  user-select: none;
  border-top: 4px solid transparent;
  margin: 0;

  li {
    display: flex;
  }
`;

const drawerItemCSS = css`
  font-size: 15px;
  padding: 10px 10px 10px 20px;
  user-select: none;
  font-weight: 400;
  border-left: 5px solid transparent;
`;

const drawerItemHoverable = css`
  ${drawerItemCSS};
  :hover {
    cursor: pointer;
    border-left: 5px solid ${colors.fallbrookGreen};
    color: ${colors.fallbrookGreen};

    @media (pointer: coarse) {
      color: inherit;
      border-left: 5px solid transparent;
    }
  }
`;

const drawerItemActive = css`
  ${drawerItemCSS};
  color: ${colors.fallbrookGreen};
  border-left: 5px solid ${colors.fallbrookGreen};
  :hover {
    cursor: initial;
  }
`;

const drawerHeader = css`
  text-transform: uppercase;
  padding: 10px;
  border-top: 1px solid ${colors.lightCharcoal};
  font-size: 12px;
  font-weight: bold;
  color: ${colors.charcoal};
`;

const drawerHeaderFirst = css`
  ${drawerHeader};
  border-top: none;
`;

const drawerSignOut = css`
  ${drawerItemHoverable};
  border-top: 1px solid ${colors.lightCharcoal};
  :hover {
    border-left: 5px solid ${colors.fallbrookGreen};
    color: ${colors.fallbrookGreen};

    @media (pointer: coarse) {
      border-left: 5px solid transparent;
      color: inherit;
    }
  }
`;

const drawerClose = css`
  position: absolute;
  top: 2px;
  right: 0px;
  padding: 10px;
  font-size: 12px;
  color: ${colors.lightCharcoal};
  :hover {
    cursor: pointer;
  }
`;

const logoCSS = css`
  height: 55px;
  :hover {
    cursor: pointer;
  }
`;

const iconCSS = css`
  display: flex;
  align-items: center;
  font-size: 25px;
  i:hover {
    cursor: pointer;
    color: ${colors.charcoal};
  }
`;

const notificationDropdownItem = css`
  font-size: 15px;
  padding: 10px;
`;

const notificationDropdownContainer = css`
  :hover {
    background: transparent;
    cursor: initial;
  }

  .notification-item {
    ${notificationDropdownItem};
  }
`;

export function isActivePath(path: string) {
  if (path === "index") {
    return window.location.pathname === "/";
  }

  return new RegExp(`${path}$`).test(window.location.pathname);
}

const findNavItemStyle = (path: string) =>
  isActivePath(path) ? drawerItemActive : drawerItemHoverable;

const HeaderMobile = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const history = useHistory();
  const firebase = useFirebase();

  const userProfile = useTypedSelector((state) => state.firebase.profile);
  const [showMenuDrawer, setShowMenuDrawer] = useState(false);

  const handleMenuClick = (route: string) => {
    setShowMenuDrawer(false);
    history.push(route);
  };

  const notificationDropdown = (
    <Menu>
      <Menu.Item css={notificationDropdownContainer} key="1">
        <div className="notification-item">No notifications at this time</div>
      </Menu.Item>
    </Menu>
  );

  const NavItem = ({ title, path }: { title: string; path: string }) => (
    <div
      css={findNavItemStyle(path)}
      onClick={() => handleMenuClick(path)}
      key={`menu-title`}
    >
      {title}
    </div>
  );

  return (
    <nav
      css={
        window.location.pathname.includes("profile")
          ? mobileNavContainerCSS
          : mobileNavContainerBoxShadow
      }
    >
      <ul css={mobileNavCSS}>
        <li>
          <img
            src={logo}
            alt="Fallbrook Chamber of Commerce Auction"
            onClick={() => handleMenuClick("/")}
            css={logoCSS}
          />
        </li>
        {isAuthenticated ? (
          <li>
            <Dropdown
              overlay={notificationDropdown}
              trigger={["click"]}
              placement="bottomCenter"
            >
              <Button
                icon={<BellOutlined css={iconCSS} />}
                type="link"
                style={{ marginRight: 15 }}
              />
            </Dropdown>

            <Button
              onClick={() => setShowMenuDrawer(true)}
              icon={<MenuOutlined css={iconCSS} />}
              type="link"
            />

            <Drawer
              placement="right"
              onClose={() => setShowMenuDrawer(false)}
              visible={showMenuDrawer}
              width={300}
              bodyStyle={{ padding: 0 }}
              closable={false}
              footer={
                <div>
                  {userProfile.roles?.admin ? (
                    <Button
                      onClick={() => {
                        setShowMenuDrawer(false);
                        history.push("/admin");
                      }}
                      style={{ margin: 0, padding: 0 }}
                      type="link"
                    >
                      Admin
                    </Button>
                  ) : null}
                </div>
              }
            >
              <Button
                css={drawerClose}
                icon={<CloseOutlined />}
                type="link"
                onClick={() => setShowMenuDrawer(false)}
              />
              <div css={drawerHeaderFirst}>Main Menu</div>
              <NavItem title="Dashboard" path="/" />

              <div css={drawerHeader}>Account Settings</div>
              <NavItem title="Contact Info" path="/settings/profile" />
              <NavItem title="Security" path="/settings/security" />
              <NavItem title="Billing" path="/settings/billing" />

              <div
                css={drawerSignOut}
                onClick={() => {
                  firebase.logout();
                  setShowMenuDrawer(false);
                }}
              >
                Sign Out
              </div>
            </Drawer>
          </li>
        ) : (
          <li>
            <Button onClick={() => history.push("/login")} type="link">
              Sign In
            </Button>
            <Button
              onClick={() => history.push("/register")}
              type="primary"
              style={{ fontWeight: "bold" }}
            >
              Create Account
            </Button>
          </li>
        )}
      </ul>
    </nav>
  );
};
export default HeaderMobile;
