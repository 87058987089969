import React, { useState, forwardRef } from "react";
import { AuctionItem } from "../auctionsSlice";
import { Popconfirm, Button as AntButton } from "antd";

const BidPopoverButton = ({
  item,
  onBid,
  disabled = false,
}: {
  item: AuctionItem;
  onBid: () => void;
  disabled: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const Button = forwardRef((props, ref) => (
    <AntButton
      type="primary"
      size="large"
      block
      loading={loading}
      disabled={disabled}
      {...props}
    >
      Bid
    </AntButton>
  ));

  return disabled ? (
    <Button />
  ) : (
    <Popconfirm
      placement="top"
      title={`You are going to bid ${(
        (item.currentAmount || item.starting_bid) + item.minimum_bid_increment
      ).toLocaleString("en-us", {
        style: "currency",
        currency: "USD",
      })}. Is this correct?`}
      onConfirm={async () => {
        setLoading(true);
        try {
          await onBid();
        } catch (err) {}
        setLoading(false);
      }}
      // async () => {
      //   const resultAction = await dispatch(
      //     makeBid({
      //       event_id: eventId,
      //       item_id: item.id,
      //       amount:
      //         (item.currentAmount || item.starting_bid) +
      //         item.minimum_bid_increment,
      //     })
      //   );
      //   if (makeBid.fulfilled.match(resultAction)) {
      //     message.success("Your bid was placed!");
      //   } else {
      //     if (resultAction.payload) {
      //       const knownMessage = resultAction.payload.message;
      //       message.error(
      //         knownMessage
      //           ? knownMessage
      //           : "There was an error, try again."
      //       );
      //     }
      //   }
      // }
      // }

      okText="Confirm"
      cancelText="Cancel"
    >
      <Button />
    </Popconfirm>
  );
};

export default BidPopoverButton;
